<template>
  <v-row class="props-list">
    <v-col md="2">Тикер</v-col>
    <v-col md="10">{{ index.ticker }}</v-col>

    <v-col md="2">Название</v-col>
    <v-col md="10">
      <v-text-field :value="index.name" dense @input="setField('name', $event)" />
    </v-col>

    <v-col md="2">Полное название</v-col>
    <v-col md="10">
      <v-text-field :value="index.fullName" dense @input="setField('fullName', $event)" />
    </v-col>

    <v-col md="2">Slug</v-col>
    <v-col md="10">
      {{ index.slug }}

      <v-btn class="ml-2" :href="url" target="_blank" color="primary" small>Посмотреть</v-btn>
    </v-col>

    <v-col md="2">Страна</v-col>
    <v-col md="10">{{ index.country }}</v-col>

    <v-col md="2">Валюта</v-col>
    <v-col md="10">{{ index.currency }}</v-col>

    <v-col md="2">Описание</v-col>
    <v-col md="10">
      <v-chip
        v-for="lang of descriptionLanguages"
        :key="lang"
        :color="lang === currentLang ? 'primary' : ''"
        class="mr-4"
        @click="currentLang = lang"
      >
        {{ lang.toUpperCase() }}
      </v-chip>

      <wysiwyg-editor
        class="mt-4"
        :value="index.description[currentLang]"
        @input="setField(`description.${currentLang}`, $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import { Index } from "~/libs/indices/index";

export default {
  mixins: [editorFields],
  props: {
    index: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentLang: "ru"
    };
  },
  computed: {
    url() {
      return Index.createFullUrl(this.index.slug);
    },
    descriptionLanguages() {
      return Object.keys(this.index.description);
    }
  }
};
</script>
