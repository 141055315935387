<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'indices-index' }" />

    <template v-if="index">
      <h2 class="mb-4">{{ ticker }}</h2>

      <v-sheet elevation="1">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>

          <v-tab-item class="pa-3">
            <index-summary :index="index" @change-field="changeField" />

            <div class="text-right">
              <v-btn color="accent" :loading="updating" @click="updateIndex">Сохранить</v-btn>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Индекс по тикеру "{{ ticker }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { editorForm } from "~/mixins/multi-editor/editor-form";

import { UpdateIndexDto } from "~/libs/indices/update-indices-dto";

import IndexSummary from "~/components/indices/ticker/summary";

export default {
  mixins: [editorForm],
  data() {
    return {
      index: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchIndex();
  },
  computed: {
    ticker() {
      return this.$route.params.ticker;
    }
  },
  methods: {
    async fetchIndex() {
      this.loading = true;

      try {
        this.index = await this.$axios.$get(`indices/${this.ticker}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по тикеру: "${this.ticker}"` + html);
      } finally {
        this.loading = false;
      }
    },

    async updateIndex() {
      this.updating = true;

      try {
        this.stock = await this.$axios.$patch(`indices/${this.ticker}`, new UpdateIndexDto(this.index));

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    changeField(payload) {
      this.commitChange(this.index, payload);
    }
  },
  components: {
    IndexSummary
  }
};
</script>
